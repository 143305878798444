import React from "react"

import { useTranslation } from "react-i18next"
import { I18N_NAMESPACES } from "../i18next/i18n"
import { Typography, Button, Box } from "@material-ui/core"
import { DONOTSKIP_URLS } from "@arnaud-cortisse/donotskip-core"
import CoachesImages from "./coaches-images"

interface HeroCoaches {
  siteTitle: string
}
const HeroCoaches: React.FC<HeroCoaches> = ({ siteTitle }) => {
  const { t: tCoaches } = useTranslation(I18N_NAMESPACES.COACHES)
  const { t: tCommon } = useTranslation(I18N_NAMESPACES.COMMON)

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <Typography variant="h3" style={{ textAlign: "center" }}>
        {siteTitle}
      </Typography>
      <Typography variant="subtitle1" style={{ textAlign: "center" }}>
        {tCommon("Never skip another workout")}
      </Typography>
      <Box my={3} />
      <div style={{ width: `100%`, marginBottom: "60px" }}>
        <Typography variant="h4" style={{ marginBottom: "1rem" }}>
          {tCoaches("How it works")}
        </Typography>
        <CoachesImages />
      </div>
      <Box display="flex" justifyContent="center">
        <Button
          href={DONOTSKIP_URLS.DONOTSKIP_COACHES_APP}
          variant="contained"
          color="primary"
        >
          {tCoaches("I create my program")}
        </Button>
      </Box>
    </div>
  )
}

export default HeroCoaches

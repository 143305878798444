import React from "react"
import { Box, Grid, Card, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { I18N_NAMESPACES } from "../i18next/i18n"

interface QuestionAnswerProps {
  question: string
  answer: string
}
const QuestionAnswer: React.FC<QuestionAnswerProps> = ({
  question,
  answer,
}) => {
  return (
    <Grid item xs={12} md={6}>
      <Box margin={1}>
        <Typography variant="h5">{question}</Typography>
        <Typography variant="body1">{answer}</Typography>
      </Box>
    </Grid>
  )
}

const Faq: React.FC = () => {
  const { t: tCommon } = useTranslation(I18N_NAMESPACES.COMMON)
  const { t: tCoaches } = useTranslation(I18N_NAMESPACES.COACHES)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ marginBottom: "1rem" }}>
          {tCommon("Frequently asked questions")}
        </Typography>
      </Grid>
      <QuestionAnswer
        question={tCoaches("question_1")}
        answer={tCoaches("reply_1")}
      />
      <QuestionAnswer
        question={tCoaches("question_2")}
        answer={tCoaches("reply_2")}
      />
    </Grid>
  )
}

export default Faq

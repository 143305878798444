import React from "react"
import { StaticQuery, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Slider, { Settings } from "react-slick"
import { Typography, Box } from "@material-ui/core"
import { I18N_NAMESPACES } from "../i18next/i18n"
import { useTranslation } from "@3nvi/gatsby-theme-intl"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const SliderSettings: Settings = {
  dots: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 10000,
  infinite: true,
  slidesToShow: 1,
  draggable: true,
  pauseOnHover: false,
}

interface ImagesProps {}
const CoachesImages: React.FC<ImagesProps> = () => {
  const { t: tCoaches } = useTranslation(I18N_NAMESPACES.COACHES)

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "coaches" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const elements = (data.allFile.nodes as any[])
    .map(node => ({ ...node, index: parseInt(node.name) }))
    .sort((elementA, elementB) => elementA.index - elementB.index)

  return (
    <Slider {...SliderSettings}>
      {elements.map((element, index) => {
        const { childImageSharp } = element
        return (
          <Box
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ display: "flex" }}>
              <Typography variant="h6">{index + 1}. </Typography>
              <Box marginX={2} />
              <Typography variant="h6">
                {tCoaches(`COACH_IMAGE_${element.index}`)}
              </Typography>
            </Box>
            <Img fluid={childImageSharp.fluid} alt={`Coach slide ${index}`} />
          </Box>
        )
      })}
    </Slider>
  )
}

export default CoachesImages

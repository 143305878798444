import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import MyAppBar from "../components/my-app-bar"
import { ThemeProvider } from "@material-ui/styles"
import theme from "../styles/theme"
import { Container, Box } from "@material-ui/core"
import Footer from "../components/footer"
import { useStaticQuery, graphql } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import headerImage from "../images/header.png"
import { Languages } from "@arnaud-cortisse/donotskip-core"
import HeroCoaches from "../components/hero-coaches"
import Faq from "../components/faq"

interface IndexPageProps {
  languageId: Languages.Ids
}
const CoachesLandingPage: React.FC<IndexPageProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const siteTitle = data.site.siteMetadata.title

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <div
          style={{
            backgroundImage: `url(${headerImage})`,
            position: "absolute",
            top: 0,
            zIndex: -5,
            height: "100%",
            width: "100%",
            opacity: 0.5,
          }}
        />
        <MyAppBar siteTitle={"DoNotSkip"} />
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container maxWidth="md" style={{ flex: 1 }}>
            <HeroCoaches siteTitle={siteTitle} />
            <Box my={10} />
            <Faq />
          </Container>
          <Footer siteTitle={siteTitle} />
        </div>
      </Layout>
    </ThemeProvider>
  )
}

export default CoachesLandingPage
